import React, { useContext } from "react"

import { useFooter } from "../../hooks/use-footer"
import { Locale } from "../../context"

const Footer = () => {
  let { locale } = useContext(Locale)
  const footer = useFooter()

  return (
    <footer className="bg-white">
      <div className="container py-10 mx-auto md:flex md:items-center md:justify-between">
        <div className="flex justify-center md:order-2">
          {footer[locale].socialLinks.map((item, index) => (
            <a
              href={item.link}
              className="ml-6 text-gray-400 hover:text-gray-500 first:ml-0"
              target="_blank"
              rel="noreferrer noopener"
              key={index}
            >
              <span className="sr-only">{item.name}</span>
              <img
                src={item.icon.url}
                alt={item.name}
                className="w-5 h-auto transition duration-200 hover:opacity-75"
              />
            </a>
          ))}
        </div>
        {footer[locale].legalNotice && (
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-base leading-6 text-center text-gray-400">
              {footer[locale].legalNotice}
            </p>
          </div>
        )}
      </div>
    </footer>
  )
}

export default Footer

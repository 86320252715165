import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Hero from "./Hero"
import Footer from "./Footer"

import Layout from "../../components/Layout"
import Content from "../../components/Content"

const Index = ({ data }) => (
  <Layout hideHeader hideFooter hideNewsletter>
    <HelmetDatoCms
      htmlAttributes={{
        lang: data.landingPage.locale,
      }}
      seo={data.landingPage.seoMetaTags}
    >
      {data.landingPage._allSlugLocales.map((version, index) => (
        <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/${version.value}`} key={index} />
      ))}
    </HelmetDatoCms>
    <Hero data={data} />
    <Content content={data.landingPage.content} />
    <Footer />
  </Layout>
)

export const query = graphql`
  query LandingPageQuery($locale: String!, $slug: String!) {
    landingPage: datoCmsLandingPage(locale: { eq: $locale }, slug: { eq: $slug }) {
      ...LandingPage
    }
  }
`

export default Index

import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import { Locale } from "../../context"
import { Input, Modal } from "../../components/utils"

import { useForm } from "../../hooks/use-form"

const Form = ({ title, subtitle, ctaTitle, reference }) => {
  let { locale } = useContext(Locale)
  const form = useForm()

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [country, setCountry] = useState()
  const [newsletter, setNewsletter] = useState(true)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then(response => setCountry(response.data.country))
  }, [])

  const onSubmit = event => {
    event.preventDefault()
    setLoading(true)
    setErrors([])

    const apimoForm = {
      reference: window.location.href,
      firstname: firstName,
      lastname: lastName,
      email: email,
  }

  async function sendFormEmail(data) {
    await window  
    .fetch(`https://b6n49xm655.execute-api.eu-west-1.amazonaws.com/production/send-homepage-email`, {  
      // .fetch(`/api/send-homepage-contact-form`, {
        method: `POST`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(emailForm),
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          //setSucceeded(true);
        }
        else {
          alert('Network error. Please try again later.');
      }
      })
      // .then(() =>{
      //   sendApimoForm(apimoForm);
      // } )
  }

    async function sendApimoForm(data) {
      await window    
      .fetch(`/api/send-apimo-data`, {
        method: `POST`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
      .then(res => res.json())
    }

    // Get HubSpot token
    const hubspotutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1")
  
    const form = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      newsletter,
      reference,
      locale,
      location: window.location.href,
      referrer: document.referrer,
      adwords: !!window.location.href.match(/gclid/gi),
      hubspotutk
    }

    const emailForm = {
      firstname: firstName,
      lastname: lastName,
      email,
      phone,
      newsletter,
      location: window.location.href,
    }

    axios
      .post(process.env.GATSBY_API_ENDPOINT + "/prod/form/submit", form)
      .then(() => {
        //sendFormEmail(emailForm)
        //  sendApimoForm(apimoForm)
        // Display success message
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhone("")
        setCountry("")
        setNewsletter(true)
        setStatus(true)
         // Redirect to Thank You page
        navigate(`/${locale}/thank-you`)
      })
      .catch(error => setErrors(error.response.data.errors))
      .finally(() => setLoading(false))
  }
  

  return (
    <>
      <div className="bg-white border border-gray-200 rounded-lg shadow-lg sm:overflow-hidden contact">
        <div className="px-6 py-8 sm:p-10">
          <div className="mb-2">
            <h3 className="mb-2 text-2xl uppercase font-headline">
              {title || form[locale].title}
            </h3>
            <hr className="w-24 mt-2 mb-4 border border-black" />
            <div
              className="mb-4 text-gray-500"
              dangerouslySetInnerHTML={{
                __html:
                  subtitle ||
                  form[locale].descriptionNode.childMarkdownRemark.html,
              }}
            />
            <form onSubmit={onSubmit} className="space-y-4">
              <div>
                <Input
                  label={form[locale].firstName}
                  name="first_name"
                  type="text"
                  placeholder={form[locale].firstNamePlaceholder}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  error={
                    errors.hasOwnProperty("first_name") && errors.first_name
                  }
                />
              </div>
              <div>
                <Input
                  label={form[locale].lastName}
                  name="last_name"
                  type="text"
                  placeholder={form[locale].lastNamePlaceholder}
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  error={errors.hasOwnProperty("last_name") && errors.last_name}
                />
              </div>
              <div>
                <Input
                  label={form[locale].email}
                  name="email"
                  type="email"
                  placeholder={form[locale].emailPlaceholder}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  error={errors.hasOwnProperty("email") && errors.email}
                />
              </div>
              <div>
                <Input
                  label={form[locale].phone}
                  name="phone"
                  type="tel"
                  placeholder={form[locale].phonePlaceholder}
                  value={phone}
                  onChange={e => setPhone(e)}
                  error={errors.hasOwnProperty("phone") && errors.phone}
                  country={country}
                />
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="border-gray-300 rounded shadow-sm text-gold focus:border-black focus:ring focus:ring-black"
                    onChange={e => setNewsletter(!newsletter)}
                    checked={newsletter}
                  />
                  <span className="ml-2">{form[locale].newsletter}</span>
                </label>
              </div>
              <button
                type="submit"
                className={`${
                  loading
                    ? `bg-opacity-50 cursor-not-allowed`
                    : `hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-black`
                } w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-black transition duration-200 mt-2`}
                disabled={loading}
              >
                {loading
                  ? form[locale].loading
                  : ctaTitle || form[locale].ctaTitle}
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* <Modal
        title={form[locale].successTitle}
        subtitle={form[locale].successSubtitle}
        close={form[locale].successClose}
        show={status}
        onClose={() => setStatus(false)}
        small
        center
      /> */}
    </>
  )
}

export default Form
